import React from "react";
import styles from "./TableBook.module.css";
import { Link } from "react-router-dom";
function TableBook() {
  return (
    <div className="container">
      <div className={styles.tableBook} data-aos="fade-up" data-aos-duration="2000">
        <h1>
          Book <span>Your</span> Table!
        </h1>
        <p>Call (210)-804-0019 during business hours to reserve</p>
        <Link to="/menu">
          {" "}
          <button>MENU</button>
        </Link>
      </div>
    </div>
  );
}

export default TableBook;
