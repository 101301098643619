import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./OurReviews.module.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";
const CustomStarIcon = ({ filled }) => {
  const filledStarSvg = (
    <svg width="17" height="17" viewBox="0 0 1792 1792">
      <path
        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
        fill="#fb8e28"
      ></path>
    </svg>
  );

  const emptyStarSvg = (
    <svg width="17" height="17" viewBox="0 0 1792 1792">
      <path
        d="M1201 1004l306-297-422-62-189-382-189 382-422 62 306 297-73 421 378-199 377 199zm527-357q0 22-26 48l-363 354 86 500q1 7 1 20 0 50-41 50-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
        fill="#ccc"
      ></path>
    </svg>
  );

  return filled ? filledStarSvg : emptyStarSvg;
};

const CustomPointIcon = () => {
  // SVG icon for point ratings
  return (
    <svg width="17" height="17" viewBox="0 0 1792 1792">
      <path
        d="M1250 957l257-250-356-52-66-10-30-60-159-322v963l59 31 318 168-60-355-12-66zm452-262l-363 354 86 500q5 33-6 51.5t-34 18.5q-17 0-40-12l-449-236-449 236q-23 12-40 12-23 0-34-18.5t-6-51.5l86-500-364-354q-32-32-23-59.5t54-34.5l502-73 225-455q20-41 49-41 28 0 49 41l225 455 502 73q45 7 54 34.5t-24 59.5z"
        fill="#fb8e28"
      ></path>
    </svg>
  );
};

const OurReviews = () => {
  const [businessData, setBusinessData] = useState({
    name: "",
    rating: 5, // Assuming a default rating for the business
    reviews: [],
    user_ratings_total: "",
  });
  const [resultOnPage, setResultOnPage] = useState(3);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setWindowWidth(window.innerWidth);
      if (windowWidth < 800) {
        setResultOnPage(1);
      } else if (windowWidth < 1250) {
        setResultOnPage(2);
      } else {
        setResultOnPage(3);
      }
    };
    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [windowWidth]);

  useEffect(() => {
    axios
      .get("https://koreana.restaurant/reviews/")  // production URL
      .then((response) => {
        const { name, rating, reviews, user_ratings_total } = response.data.result;
        setLoading(false);
        setBusinessData({
          name,
          rating,
          reviews,
          user_ratings_total,
        });

        // console.log(`OurReviews.jsx response: ${JSON.stringify(response.data, null, 2)}`);
      })
      .catch((error) => {
        console.error("Error fetching business details and reviews:", error);
      });
  }, []);

  const renderRatingIcons = (rating) => {
    const ratingIcons = [];

    if (Number.isInteger(rating)) {
      // Render star icons for whole number ratings
      for (let i = 1; i <= 5; i++) {
        ratingIcons.push(<CustomStarIcon key={i} filled={i <= rating} />);
      }
    } else {
      // Calculate the number of filled and empty icons for point ratings
      const filledIcons = Math.floor(rating);
      const decimalPart = rating - filledIcons;
      const emptyIcons = 5 - filledIcons - (decimalPart > 0 ? 1 : 0);

      for (let i = 0; i < filledIcons; i++) {
        ratingIcons.push(<CustomStarIcon key={`filled-${i}`} filled={true} />);
      }

      if (decimalPart > 0) {
        ratingIcons.push(<CustomPointIcon key="point-icon" />);
      }

      for (let i = 0; i < emptyIcons; i++) {
        ratingIcons.push(<CustomStarIcon key={`empty-${i}`} filled={false} />);
      }
    }

    return ratingIcons;
  };

  return (
    <div className="container">
      <div className={styles.ourReviewsHeader} data-aos="fade-up" data-aos-duration="1500">
        <h1>
          Customer <span>Reviews</span>
        </h1>
        <p>What Our Customer Say About Us</p>
      </div>
      <div className={styles.ourReviews} data-aos="fade-up" data-aos-duration="2000">
        {loading ? (
          <div className={styles.loader}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ margin: "auto", background: "#fff" }}
              width="200"
              height="200"
              display="block"
              preserveAspectRatio="xMidYMid"
              viewBox="0 0 100 100"
            >
              <circle
                cx="50"
                cy="50"
                r="0"
                fill="none"
                stroke="#a10808"
                strokeWidth="2"
              >
                <animate
                  attributeName="r"
                  begin="0s"
                  calcMode="spline"
                  dur="1s"
                  keySplines="0 0.2 0.8 1"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="0;40"
                ></animate>
                <animate
                  attributeName="opacity"
                  begin="0s"
                  calcMode="spline"
                  dur="1s"
                  keySplines="0.2 0 0.8 1"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                ></animate>
              </circle>
              <circle
                cx="50"
                cy="50"
                r="0"
                fill="none"
                stroke="#000"
                strokeWidth="2"
              >
                <animate
                  attributeName="r"
                  begin="-0.5s"
                  calcMode="spline"
                  dur="1s"
                  keySplines="0 0.2 0.8 1"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="0;40"
                ></animate>
                <animate
                  attributeName="opacity"
                  begin="-0.5s"
                  calcMode="spline"
                  dur="1s"
                  keySplines="0.2 0 0.8 1"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                ></animate>
              </circle>
            </svg>
            <h4>Reviews are loading...</h4>
          </div>
        ) : (
          <>
            <div className={styles.shopDetails}>
              <img
                src="https://lh3.googleusercontent.com/p/AF1QipMoQqpjXG1OPkH7e1abfZY-0r9gXtGmFaCttGzT=s1360-w1360-h1020"
                alt="Place main"
              />
              <a
                href="https://www.google.com/maps?cid=3259221795650919126"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h1>{businessData.name}</h1>
              </a>
              <div className={styles.ratingOfShop}>
                <h3>{businessData.rating}</h3>
                <div>{renderRatingIcons(businessData.rating)}</div>
              </div>
              <h5>FROM GOOGLE</h5>
              <p>
                Based on <span>{businessData.user_ratings_total}</span> reviews
              </p>
              <a
                href="https://search.google.com/local/writereview?placeid=ChIJS4edVeX0XIYR1tr7ffQUOy0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>
                  Write a review on{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <script xmlns="">
                      window._wordtune_extension_installed = true;
                    </script>
                    <path
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      fill="#EA4335"
                    />
                    <path d="M1 1h22v22H1z" fill="none" />
                  </svg>
                </button>
              </a>
            </div>
            <Swiper
              slidesPerView={resultOnPage}
              spaceBetween={20}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className={styles.reviews}
            >
              {businessData.reviews?.map((review, index) => (
                <SwiperSlide key={index} className={styles.review}>
                  <div className={styles.reviewTop}>
                    <img src={review.profile_photo_url} alt="User profile" />
                    <a
                      href={review.author_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>{review.author_name}</h3>
                    </a>
                    <div className={styles.ratingReviewTop}>
                      {renderRatingIcons(review.rating)}
                    </div>
                    <p>{review.relative_time_description}</p>
                  </div>
                  <div className={styles.reviewText}>
                    {review.text === "" ? (
                      <div className={styles.noReviewText}>
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={1.5}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        <h4>No review text added here!</h4>
                      </div>
                    ) : (
                      review.text
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </div>
    </div>
  );
};

export default OurReviews;
