import React from "react";
import styles from "./AboutContent.module.css";
import aboutContentImage from "../../assets/about-content.jpg";
function AboutContent() {
  return (
    <div className="container">
      <div className={styles.aboutContent}>
        <img src={aboutContentImage} alt="About content" />
        <p>
          Mira Kim, the proud new owner of Koreana, brings her entrepreneurial
          spirit and passion for excellence to the Lone Star State.
          Having successfully operated several nail salons in
          Pennsylvania, she decided to embark on a new journey that aligns with
          her love for authentic Korean cuisine. Her dedication to quality and
          authenticity is reflected in every dish served at Koreana. Mira's
          commitment to delivering the best authentic Korean food experience to
          Texans resonates deeply with her customers. Come join us at Koreana,
          where her vision comes to life, offering a taste of Korea that is
          both genuine and delightful.
        </p>
      </div>
    </div>
  );
}

export default AboutContent;
