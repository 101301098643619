import React from "react";
import styles from "./OurStory.module.css";
import storyImage1 from "../../assets/our-story-1.jpeg";
import storyImage2 from "../../assets/our-story-2.jpeg";
function OurStory() {
  return (
    <div className="container">
      <div className={styles.ourStory}>
        <div
          className={styles.leftSide}
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom"
        >
          <img src={storyImage1} alt="Story" />
          <img src={storyImage2} alt="Story" />
        </div>
        <div
          className={styles.rightSide}
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <h3>
            OUR <span> STORY</span>
          </h3>
          <h1>
            Family-Owned Korean Restaurant Serving San Antonio For Over 25
            Years.
          </h1>
          <p>
            A Korean restaurant located in the northeast of San Antonio.
            Recently under new management, the restaurant is now owned and
            operated by Mira Kim. The restaurant prides itself on providing
            customers with an authentic taste of Korean cuisine. Whether you’re
            a seasoned Korean food enthusiast or trying it for the first time,
            the team at Koreana is dedicated to ensuring that you have a
            memorable and enjoyable dining experience. Come and enjoy a taste of
            Korea in San Antonio!
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
