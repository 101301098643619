import React, { useState } from "react";
import styles from "./Navbar.module.css";
import logo from "../../assets/Logo-Korean-Resturent.png";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const [isActiveMobile, setIsActiveMobile] = useState(null);
  return (
    <nav className={styles.nav}>
      <Link to={"/"}>
        <div className={styles.logo}>
          <img src={logo} alt="Website logo" />
        </div>
      </Link>
      <svg
        fill="none"
        stroke="currentColor"
        strokeWidth={1.5}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className={styles.menuIcon}
        onClick={() => setIsActiveMobile(!isActiveMobile)}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
        />
      </svg>
      <div
        className={styles.links + " " + (isActiveMobile ? styles.active : "")}
      >
        <ul>
          <Link
            to="/"
            className={location.pathname === "/" ? styles.active : ""}
          >
            <li>HOME</li>
          </Link>
          <Link
            to="/menu"
            className={location.pathname === "/menu" ? styles.active : ""}
          >
            <li>MENU</li>
          </Link>
          <Link
            to="/about"
            className={location.pathname === "/about" ? styles.active : ""}
          >
            <li>ABOUT</li>
          </Link>
          <Link
            to="/gallery"
            className={location.pathname === "/gallery" ? styles.active : ""}
          >
            <li>GALLERY</li>
          </Link>
          <Link
            to="/contact"
            className={location.pathname === "/contact" ? styles.active : ""}
          >
            <li>CONTACT</li>
          </Link>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
