import React from "react";
import styles from "./HeroSection.module.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
function HeroSection() {
    return (
        <div className={styles.heroSection}>
            <Navbar />
            <div className={styles.heroContent} data-aos="fade-down" data-aos-duration="2000">
                <h1>
                    Welcome to Koreana Restaurant where Seoul meets Food
                </h1>
                <p>
                    Most authentic Korean cuisine you'll ever experience
                </p>
                <Link to={"/menu"}>
                    <button>MENU</button>
                </Link>
            </div>
        </div>
    );
}

export default HeroSection;
