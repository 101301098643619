import React from "react";
import styles from "./Popular.module.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow } from "swiper/modules";
import popularImage1 from "../../assets/most-popular-1.jpg";
import popularImage2 from "../../assets/most-popular-2.jpg";
import popularImage3 from "../../assets/most-popular-3.jpg";
import popularImage4 from "../../assets/most-popular-4.jpg";
import popularImage5 from "../../assets/most-popular-5.jpg";

function Popular() {
  return (
    <div className="container">
      <div className={styles.popular}>
        <h1 data-aos="fade-up" data-aos-duration="1500">
          Popular <span>Items</span>
        </h1>
        <p data-aos="fade-up" data-aos-duration="2000">
          These are some of the most popular items that you will find on our
          menu.
        </p>
        <div className={styles.popularContent} data-aos="fade-up" data-aos-duration="2500">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            initialSlide={2}
            slidesPerView={"auto"}
            coverflowEffect={{
              effect: "coverflow",
              grabCursor: true,
              centeredSlides: true,
              slidesPerView: "auto",
              rotate: 20,
              stretch: 10,
              depth: 50,
              modifier: 1,
              slideShadows: true,
            }}
            modules={[EffectCoverflow]}
            className={styles.sliderContainer}
          >
            <SwiperSlide className={styles.item}>
              <img src={popularImage1} alt="Swipper 1" loading="lazy" />
            </SwiperSlide>
            <SwiperSlide className={styles.item}>
              <img src={popularImage2} alt="Swipper 1" loading="lazy" />
            </SwiperSlide>
            <SwiperSlide className={styles.item}>
              <img src={popularImage3} alt="Swipper 1" loading="lazy" />
            </SwiperSlide>
            <SwiperSlide className={styles.item}>
              <img src={popularImage4} alt="Swipper 1" loading="lazy" />
            </SwiperSlide>
            <SwiperSlide className={styles.item}>
              <img src={popularImage5} alt="Swipper 1" loading="lazy" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Popular;
