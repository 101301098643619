import React, { useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import styles from "./Gallery.module.css";

const GalleryComponent = () => {
  const folderPath = "../gallery/"; // Replace with your folder path

  const imagePaths = [
    "o.jpg",
    "o-1.jpg",
    "o-2.jpg",
    "o-3.jpg",
    "o-4.jpg",
    "o-5.jpg",
    "o-6.jpg",
    "o-7.jpg",
    "o-8.jpg",
    "o-9.jpg",
    "o-10.jpg",
    "o-11.jpg",
    "o-12.jpg",
    "o-13.jpg",
    "o-14.jpg",
    "o-15.jpg",
    "o-16.jpg",
    "o-17.jpg",
    "o-18.jpg",
    "o-19.jpg",
    "o-20.jpg",
    "o-21.jpg",
    "o-22.jpg",
    "o-23.jpg",
    "o-24.jpg",
    "o-25.jpg",
    "o-26.jpg",
    "o-27.jpg",
    "o-28.jpg",
    "o-29.jpg",
    "o-30.jpg",
    "o-31.jpg",
    "o-32.jpg",
    "o-33.jpg",
    "o-34.jpg",
    // Add more image paths
  ];

  const galleryImages = imagePaths.map((path) => ({
    original: folderPath + path,
    thumbnail: folderPath + path,
  }));

  const [currentIndex, setCurrentIndex] = useState(null);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="container">
      <div className={styles["gallery-container"]}>
        {imagePaths.map((path, index) => (
          <img
            key={index}
            src={folderPath + path}
            alt={`${index}`}
            className={styles.thumbnail}
            onClick={() => handleImageClick(index)}
          />
        ))}
        {currentIndex !== null && (
          <div className={styles["image-modal"]}>
            <span
              className={styles["close-modal"]}
              onClick={() => setCurrentIndex(null)}
            >
              &times;
            </span>
            <img
              src={galleryImages[currentIndex].original}
              alt={`${currentIndex}`}
            />
          </div>
        )}
        {currentIndex !== null && (
          <ImageGallery
            items={galleryImages}
            startIndex={currentIndex}
            showFullscreenButton={true}
            showPlayButton={true}
            showBullets={true}
            onClose={() => setCurrentIndex(null)}
          />
        )}
      </div>
    </div>
  );
};

export default GalleryComponent;
