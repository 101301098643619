import React, { useState } from "react";
import menuData from "../../data/menu.json"; // Make sure to import your JSON data
import styles from "./Menu.module.css";

const MenuItem = () => {
  const [activeType, setActiveType] = useState("Meat Entrées");

  const handleTypeClick = (type) => {
    setActiveType(type === activeType ? activeType : type);
  };

  const renderMenuItems = (menuItems) => {
    return menuItems.map((item, index) => (
      <div key={index} className={styles.menuItem}>
        <p>{index + 1 + "."}</p>
        <span className={styles.detailsItem}>
          <h3>{item.name}</h3>
          <p>{item.shortdes}</p>
        </span>
        <span className={styles.doted}></span>
        <span className={styles.itemPrice}>{item.price}</span>
      </div>
    ));
  };

  const renderCategories = () => {
    return menuData.categories.map((category, index) => (
      <div key={index} className={styles.menuCategory}>
        <h2
          onClick={() => handleTypeClick(category.type)}
          className={category.type === activeType ? styles.activeType : ""}
        >
          {category.type}
        </h2>
      </div>
    ));
  };

  return (
    <div className="container">
      <h1 className={styles.menuTitle}>
        Menu <span> Category</span>
      </h1>
      <p className={styles.menuDescription}>Select category to see menu list</p>
      <div className={styles.typeContainer}>{renderCategories()}</div>
      <div className={styles.ListOfItem}>
        {menuData.categories.map(
          (category) =>
            category.type === activeType && renderMenuItems(category.items)
        )}
      </div>
    </div>
  );
};

export default MenuItem;
