import React from "react";
import styles from "./OthersHero.module.css";
import Navbar from "../Navbar/Navbar";
function OthersHero({ heroTitle, heroDescription }) {
  return (
    <div className={styles.othersHero}>
      <Navbar />
      <div className={styles.content}>
        <h1>{heroTitle}</h1>
        <p>{heroDescription}</p>
      </div>
    </div>
  );
}

export default OthersHero;
