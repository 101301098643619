import React from "react";
import HeroSection from "../components/HeroSection/HeroSection";
import OurStory from "../components/OurStory/OurStory";
import Popular from "../components/Popular/Popular";
import OurReviews from "../components/OurReviews/OurReviews";
import TableBook from "../components/TableBook/TableBook";

function Home() {
  return (
    <div>
      <HeroSection />
      <OurStory />
      <Popular />
      <OurReviews />
      <TableBook />
    </div>
  );
}

export default Home;
